import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// This reusable component is a standalone component responsible to handle "all notifications" only

@Component({
  selector: 'app-all-notifications-settings',
  templateUrl: './all-notifications-settings.component.html',
  styleUrls: ['./all-notifications-settings.component.scss'],
})
export class AllNotificationsSettingsComponent implements OnInit {
  @Input() public settings: any;
  @Input() public loading: boolean = false;
  @Input() public success: boolean = false;
  @Output() updateSettings = new EventEmitter<any>();

  // Configure all the notifications here (email and push)
  notificationEmailArray = [
    'notificationsFlangeWarningEmail',
    'notificationsPipeWarningEmail',
    'notificationsSteamtrapWarningEmail',
    'notificationsNanoWarningEmail',
    'notificationsFlangeWorkingEmail',
    'notificationsPipeWorkingEmail',
    'notificationsSteamtrapWorkingEmail',
    'notificationsNanoWorkingEmail',
    'notificationsFlangeAlarmEmail',
    'notificationsPipeAlarmEmail',
    'notificationsSteamtrapAlarmEmail',
    'notificationsNanoCriticalEmail',
  ];
  notificationPushArray = [
    'notificationsFlangeWarningPush',
    'notificationsPipeWarningPush',
    'notificationsSteamtrapWarningPush',
    'notificationsNanoWarningPush',
    'notificationsFlangeWorkingPush',
    'notificationsPipeWorkingPush',
    'notificationsSteamtrapWorkingPush',
    'notificationsNanoWorkingPush',
    'notificationsFlangeAlarmPush',
    'notificationsPipeAlarmPush',
    'notificationsSteamtrapAlarmPush',
    'notificationsNanoCriticalPush',
  ];
  showAllNotification: boolean;
  constructor(public router: ActivatedRoute) {}

  ngOnInit() {
    this.router.url.subscribe((url) => {
      this.showAllNotification = url.length === 2 ? true : false;
    });
  }

  get UserEmail(): string {
    return !!this.settings ? this.settings.email : '';
  }
  // Email notification Starts
  get WarningNotificationsEmail(): boolean {
    if (!this.settings) {
      return false;
    } else {
      let notifications = [];
      this.notificationEmailArray.forEach((val) => {
        if (this.settings[val] !== undefined || null) {
          notifications.push(this.settings[val]);
        }
      });
      return notifications.every((val) => {
        return val === true;
      });
    }
  }
  set WarningNotificationsEmail(value: boolean) {
    if (!this.settings) {
      return;
    } else {
      this.notificationEmailArray.forEach((val) => {
        this.settings[val] = value;
        this.success = true;
      });
    }
  }
  // Email notification Ends

  // Push notification Starts
  get WarningNotificationsPush(): boolean {
    if (!this.settings) {
      return false;
    } else {
      let notifications = [];
      this.notificationPushArray.forEach((val) => {
        if (this.settings[val] !== undefined || null) {
          notifications.push(this.settings[val]);
        }
      });
      return notifications.every((val) => {
        return val === true;
      });
    }
  }
  set WarningNotificationsPush(value: boolean) {
    if (!this.settings) {
      return;
    } else {
      this.notificationPushArray.forEach((val) => {
        this.settings[val] = value;
      });
    }
  }
  // Push notification Ends
}
