<app-header
  [transparent]="false"
  [title]="i18n.string('settings')"
></app-header>

<div class="content" [class.desktop]="isDesktop">
  <!--
    <div class="more-info-group"><button><app-more-info [variant]="'more-info-flat'" [svg_icon]="'assets/icons/profile.svg'" (click)="toSubpage('profile');">Edit Profile</app-more-info></button></div>
  -->
  <div class="more-info-group">
    <div class="more-info-subgroup black-bg">
      <button *ngIf="HasIAAccess">
        <app-more-info
          [variant]="'more-info-flat-white'"
          [grouped]="true"
          [blackBg]="true"
          [fillWhite]="true"
          svg_icon="icons:installation_agent"
          svg_icon_right="icons:arrow-right-up"
          (click)="toInstallagent(InstallationAgentURL)"
          >{{ i18n.string("go_to_ia") }}
        </app-more-info>
      </button>
    </div>
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:profile"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('profile')"
          >{{ i18n.string("profile") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:forgot_password"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('change-password')"
          >{{ i18n.string("change_password") }}
        </app-more-info>
      </button>
    </div>
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:language"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('language')"
          >{{ i18n.string("language") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:metric"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('metric')"
          >{{ i18n.string("measurement_system") }}
        </app-more-info>
      </button>
      <!--<button>
        <app-more-info [variant]="'more-info-flat'" [grouped]="true" svg_icon="icons:appearance"
          (click)="toSubpage('appearance');">Appearance</app-more-info>
      </button>-->
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:bell"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('notifications')"
          >{{ i18n.string("notifications") }}
        </app-more-info>
      </button>
    </div>
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:tool"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('technical_documents')"
          >{{ i18n.string("technical_documents") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:help"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('help')"
          >{{ i18n.string("help_and_support") }}
        </app-more-info>
      </button>
    </div>
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:terms"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('imprint')"
          >{{ i18n.string("imprint2") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:terms"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('terms-of-use')"
          >{{ i18n.string("terms_of_use") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:terms"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('data-protection')"
          >{{ i18n.string("data_protection_declaration") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:terms"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('service-conditions')"
          >{{ i18n.string("service_conditions") }}
        </app-more-info>
      </button>
      <button *ngIf="i18n.Lng === 'en'">
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:terms"
          svg_icon_right="icons:arrow-right"
          (click)="toSubpage('note-for-us-residents')"
          >{{ i18n.string("note_for_us_residents") }} 
        </app-more-info>
      </button>
    </div>
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:logout"
          svg_icon_right="icons:arrow-right-up"
          (click)="logout()"
          >{{ i18n.string("logout") }}
        </app-more-info>
      </button>
    </div>
  </div>
</div>
