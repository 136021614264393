import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { StorageService } from '../../../cc-framework/services/storage.service';
import { environment } from '../../../environments/environment';
import { BannerNotificationService } from '../../services/banner-notification.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get InstallationAgentURL(): string {
    return (
      environment.installation_agent_url +
      '/login/refauth/' +
      encodeURIComponent(this.storage.Token)
    );
  }

  get HasIAAccess(): boolean {
    return this.auth.checkTokenScope('ia-access');
  }

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    public route: ActivatedRoute,
    public ui: UiService,
    public banner: BannerNotificationService,
    public i18n: I18nService,
    public storage: StorageService
  ) {}

  ngOnInit() {
    this.ui.onNavBack(null);
    this.ui.setBackText(this.i18n.string('home'));
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  toSubpage(url) {
    this.router.navigate(['settings/' + url]);
  }

  toInstallagent(url) {
    location.href = url;
  }

  toTermsOfService() {
    if (this.i18n.Lng === 'de') {
      window.open(
        'https://www.henkel-adhesives.com/de/de/about/terms-of-use.html',
        '_blank'
      );
    } else {
      window.open(
        'https://www.henkel-adhesives.com/de/en/about/terms-of-use.html',
        '_blank'
      );
    }
  }

  logout() {
    this.auth.logout(() => {
      this.router.navigate(['login']);
    });
  }

  getbanner() {
    this.banner.getBannerNotifications().subscribe((res) => {
      console.log(res);
    });
  }

  dismissbanner() {
    this.banner.dismissBannerNotification('maintenance').subscribe((res) => {
      console.log(res);
    });
  }
}
